@mixin noSelect
  user-select: none
  -webkit-user-select: none
  user-drag: none
  -webkit-user-drag: none

@mixin noTouch
  touch-action: none
  -webkit-touch-action: none

@mixin highResolution
  -webkit-backface-visibility: hidden
  -ms-transform: translateZ(0)
  -webkit-transform: translateZ(0)
  transform: translateZ(0)

@mixin customButton
  color: $white
  font-size: vh(2.48)
  line-height: vh(2.89)
  font-weight: 700
  padding: vh(2.68) vh(8.26)
  border-radius: vh(4.64)
  background-color: $darkBlue
  border: none
  cursor: pointer
  &:disabled
    background-color: $grey
    color: $black50
    cursor: not-allowed
  @media #{$tablet}
    font-size: 2.34vw
    line-height: 2.73vw
    padding: 2.54vw 7.81vw
    border-radius: 4.39vw
  @media #{$mobile}
    font-size: 3.75vw
    line-height: 4.38vw
    padding: 4.38vw 10.78vw 3.75vw 10.78vw
    border-radius: 7.81vw

@mixin title
  color: $darkBlue
  text-transform: uppercase
  font-family: Prostokvashino
  background-image: url('/assets/img/title-gradient.jpg')
  background-repeat: no-repeat
  background-position: center
  background-size: 100% 100%

  background-clip: text
  -webkit-background-clip: text

  text-fill-color: transparent
  -webkit-text-fill-color: transparent

@mixin modalCloseButton
  position: absolute
  right: 0
  top: 0
  cursor: pointer
  border: none
  background: transparent
  transform: translate(50%, -50%)
  img
    display: block
    width: vh(4.95)
    @include noSelect
    @media #{$tablet}
      width: 4.69vw
    @media #{$mobile}
      width: 8.75vw

@mixin modalButton
  @include customButton
  position: absolute
  left: 50%
  bottom: 0
  transform: translate(-50%, 50%)
  padding-left: 0
  padding-right: 0
  width: vh(26.32)
  @media #{$tablet}
    width: 24.9vw
    padding-left: 0
    padding-right: 0
  @media #{$mobile}
    width: auto
    padding: 4.38vw 10.78vw 3.75vw 10.78vw

@mixin whiteBlur
  background-color: transparentize($white, 0.65)
  backdrop-filter: blur(2.5px)

@mixin stroke($color: $white, $size: 1px)
  text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color, #{$size} -#{$size} 0 $color, #{$size} 0 0 $color, #{$size} #{$size} 0 $color, 0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color
