.quality-tab
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center
  &-infospot-overlay
    @media #{$mobile}
      &--active
        position: fixed
        width: 100%
        height: 100%
        left: 0
        top: 0
        padding-top: mpx(119)
        padding-bottom: mpx(43)
        overflow-y: auto
        z-index: 15
        .quality-tab-buttons
          display: inline-flex
          position: relative
          bottom: unset
          margin-top: mpx(30)
  .infospot, .dialog-modal
    backdrop-filter: none
    background: transparent
    @media #{$mobile}
      padding-top: 0
      padding-bottom: 0
      position: relative
      width: 100%
      height: auto
      overflow: visible
  .hidden
    opacity: 0
  &-content
    width: 100%
    height: 100%
    background-color: $white
    &-overlay
      width: 100%
      height: 100%
      background-color: transparentize($blue, 0.7)
      overflow-x: hidden
      overflow-y: auto
      position: relative
    &-overflow
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      padding-top: dpx(171)
      padding-bottom: dpx(210)
      @media #{$tablet}
        padding-top: tpx(164)
        padding-bottom: tpx(182)
      @media #{$mobile}
        padding-top: mpx(107)
        padding-bottom: mpx(268)
      h1
        color: $titleGradientTo
        text-transform: uppercase
        font-family: Prostokvashino
        font-size: dpx(96)
        line-height: 90%
        letter-spacing: -0.03em
        position: relative
        z-index: 2
        @media #{$tablet}
          font-size: tpx(58)
        @media #{$mobile}
          font-size: mpx(32)
  &-clouds
    position: absolute
    left: 50%
    top: 0
    width: auto
    height: dpx(2176)
    display: block
    z-index: 2
    transform: translateX(-50%)
    @include noSelect
    @media #{$tablet}
      height: tpx(1824)
    @media #{$mobile}
      height: mpx(1174)
  &-line
    position: absolute
    left: 50%
    top: dpx(596)
    display: block
    z-index: 1
    transform: translateX(-50%) translateX(dpx(40))
    @include noSelect
    @media #{$tablet}
      top: tpx(446)
      transform: translateX(-50%) translateX(tpx(31.5))
    @media #{$mobile}
      top: mpx(310)
      transform: translateX(-50%) translateX(mpx(4))
    &--desktop
      width: dpx(639)
      height: dpx(1153)
      @media #{$tablet}
        display: none
    &--tablet
      width: tpx(465)
      height: tpx(988)
      display: none
      @media #{$tablet}
        display: block
      @media #{$mobile}
        display: none
    &--mobile
      width: mpx(244)
      height: mpx(630)
      display: none
      @media #{$mobile}
        display: block
    circle
      opacity: 0
      &:nth-last-child(1)
        opacity: 1 !important
      &:nth-last-child(2)
        @media #{$mobile}
          opacity: 1 !important
  &-steps
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 100%
    max-width: dpx(1077)
    transform: translateX(dpx(21))
    position: relative
    z-index: 2
    @media #{$tablet}
      max-width: tpx(660)
      transform: translateX(tpx(5))
    @media #{$mobile}
      max-width: mpx(268)
      transform: none
  &-step
    display: flex
    --desktop-value: 0
    --tablet-value: 0
    --mobile-value: 0
    opacity: var(--desktop-value)
    transform: scale(var(--desktop-value))
    @media #{$tablet}
      opacity: var(--tablet-value)
      transform: scale(var(--tablet-value))
    @media #{$mobile}
      opacity: var(--mobile-value)
      transform: scale(var(--mobile-value))
    &:nth-child(odd)
      margin-left: auto
      width: dpx(521)
      @media #{$tablet}
        width: tpx(280)
      @media #{$mobile}
        margin-left: 0
        margin-right: auto
        width: unset
    &:nth-child(even)
      margin-right: auto
      @media #{$tablet}
        width: tpx(260)
      @media #{$mobile}
        margin-left: auto
        margin-right: 0
        width: auto
    &:nth-child(1)
      margin-top: dpx(120)
      opacity: 1 !important
      transform: none !important
      @media #{$tablet}
        margin-top: tpx(77)
      @media #{$mobile}
        margin-top: mpx(19)
    &:nth-child(2)
      margin-top: dpx(195)
      @media #{$tablet}
        margin-top: tpx(155)
      @media #{$mobile}
        margin-top: mpx(134)
        opacity: 1 !important
        transform: none !important
    &:nth-child(3)
      margin-top: dpx(218)
      @media #{$tablet}
        margin-top: tpx(118)
      @media #{$mobile}
        margin-top: mpx(132)
    &:nth-child(4)
      margin-top: dpx(222)
      @media #{$tablet}
        margin-top: tpx(159)
      @media #{$mobile}
        margin-top: mpx(136)
    &:nth-child(5)
      margin-top: dpx(220)
      @media #{$tablet}
        margin-top: tpx(184)
        margin-right: tpx(-105)
        width: tpx(385)
      @media #{$mobile}
        margin-top: mpx(140)
        margin-right: auto
        width: auto
      p
        span
          line-height: 110%
    h2
      font-size: dpx(96)
      font-weight: 700
      line-height: 130%
      color: $darkBlue
      margin-right: dpx(17)
      @media #{$tablet}
        font-size: tpx(64)
        margin-right: tpx(15)
      @media #{$mobile}
        font-size: mpx(32)
        margin-right: mpx(8)
    p
      display: flex
      align-items: flex-start
      flex-direction: column
      padding-top: dpx(10)
      @media #{$tablet}
        padding-top: tpx(9)
      @media #{$mobile}
        padding-top: mpx(2)
      span
        font-size: dpx(48)
        font-weight: 700
        line-height: 130%
        color: $darkBlue
        @media #{$tablet}
          font-size: tpx(40)
          line-height: 110%
        @media #{$mobile}
          font-size: mpx(16)
          line-height: 130%
      a
        font-size: dpx(32)
        font-weight: 700
        line-height: 130%
        color: $blue
        text-decoration: underline
        cursor: pointer
        display: block
        margin-top: dpx(25)
        @media #{$tablet}
          font-size: tpx(24)
          margin-top: tpx(25)
        @media #{$mobile}
          font-size: mpx(11)
          margin-top: mpx(5)
          text-decoration: none
      br
        @media #{$mobile}
          display: none
  &-image
    position: absolute
    left: 50%
    z-index: 3
    transform: translateX(-50%)
    img
      cursor: pointer
      --desktop-value: 0
      --tablet-value: 0
      --mobile-value: 0
      display: block
      width: dpx(634)
      opacity: var(--desktop-value)
      transform: scale(var(--desktop-value))
      @include noSelect
      @media #{$tablet}
        width: tpx(421)
        opacity: var(--tablet-value)
        transform: scale(var(--tablet-value))
      @media #{$mobile}
        width: mpx(206)
        opacity: var(--mobile-value)
        transform: scale(var(--mobile-value))
    &--0
      transform: translateX(-50%) translateX(dpx(-325))
      top: dpx(238)
      @media #{$tablet}
        transform: translateX(-50%) translateX(tpx(-203.5))
        top: tpx(241)
      @media #{$mobile}
        transform: translateX(-50%) translateX(mpx(-45.5))
        top: mpx(196)
      img
        opacity: 1 !important
        transform: none !important
    &--1
      transform: translateX(-50%) translateX(dpx(296))
      top: dpx(537)
      @media #{$tablet}
        transform: translateX(-50%) translateX(tpx(255))
        top: tpx(532)
      @media #{$mobile}
        transform: translateX(-50%) translateX(mpx(36.5))
        top: mpx(368)
        img
          opacity: 1 !important
          transform: none !important
    &--2
      transform: translateX(-50%) translateX(dpx(-335))
      top: dpx(924)
      @media #{$tablet}
        transform: translateX(-50%) translateX(tpx(-211))
        top: tpx(797)
      @media #{$mobile}
        transform: translateX(-50%) translateX(mpx(-37))
        top: mpx(542)
    &--3
      transform: translateX(-50%) translateX(dpx(362))
      top: dpx(1256)
      @media #{$tablet}
        transform: translateX(-50%) translateX(tpx(256))
        top: tpx(1084)
      @media #{$mobile}
        transform: translateX(-50%) translateX(mpx(53))
        top: mpx(718)
    &--4
      transform: translateX(-50%) translateX(dpx(-345))
      top: dpx(1630)
      @media #{$tablet}
        transform: translateX(-50%) translateX(tpx(-215.5))
        top: tpx(1368)
      @media #{$mobile}
        transform: translateX(-50%) translateX(mpx(-38.5))
        width: mpx(197)
        top: mpx(911)
  &-buttons
    position: fixed
    left: 50%
    bottom: dpx(40)
    transform: translateX(-50%)
    display: flex
    align-items: center
    justify-content: center
    z-index: 15
    @media #{$tablet}
      bottom: tpx(58)
    @media #{$mobile}
      bottom: mpx(43)
    button
      background: transparent
      border: none
      cursor: pointer
      &:disabled
        opacity: 0.35
        cursor: not-allowed
    .quality-tab-close
      img
        display: block
        width: dpx(72)
        @media #{$tablet}
          width: tpx(72)
        @media #{$mobile}
          width: mpx(49)
    .quality-tab-arrow
      margin: 0 0 0 dpx(25)
      @media #{$tablet}
        margin: 0 0 0 tpx(25)
      @media #{$mobile}
        margin: 0 0 0 mpx(12)
      &--prev
        transform: scale(-1, 1)
        margin: 0 dpx(25) 0 0
        @media #{$tablet}
          margin: 0 tpx(25) 0 0
        @media #{$mobile}
          margin: 0 mpx(12) 0 0
      img
        display: block
        width: dpx(60)
        @media #{$tablet}
          width: tpx(60)
        @media #{$mobile}
          width: mpx(40)
