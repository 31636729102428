.intro
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 35
  overflow: hidden
  @include whiteBlur
  @media #{$mobile}
    backdrop-filter: none
    background-color: transparent
    position: absolute
    height: auto
    z-index: 5
  &--hidden-elements
    z-index: 15
    background-color: transparentize($white, 0.65)
    padding-top: dpx(166)
    @media #{$tablet}
      padding-top: 0
      display: flex
      justify-content: center
      align-items: center
    @media #{$mobile}
      align-items: flex-start
      background-color: transparent
      z-index: 5
    *
      visibility: hidden
      @media #{$mobile}
        visibility: unset
    .intro-video-badge
      display: none
      @media #{$mobile}
        display: block
    .intro-close
      display: block
      @media #{$mobile}
        display: none
    .intro-content
      padding-top: 0
      width: dpx(1200)
      height: dpx(675)
      margin: 0 auto
      @media #{$tablet}
        width: tpx(864)
        height: tpx(485)
      @media #{$mobile}
        padding-top: 34.48vw
        width: 100%
        height: auto
    .intro-video
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      @media #{$mobile}
        position: relative
  &--hidden-for-video
    z-index: 15
    background-color: transparentize($white, 0.65)
    padding-top: dpx(166)
    height: 100%
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 35
    overflow: hidden
    @include whiteBlur
    .intro-content
      padding-top: 0
      width: dpx(1200)
      height: dpx(675)
      margin: 0 auto
      @media #{$tablet}
        width: tpx(864)
        height: tpx(485)
    .intro-video
      width: 100%
      height: 100%
      position: absolute
      left: 0
      top: 0
      @media #{$mobile}
        width: 100% !important
        height: 100% !important
    .intro-close
      display: block
  &-content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    padding-top: vh(15.07)
    position: relative
    @media #{$tablet}
      padding-top: 20.02vw
    @media #{$mobile}
      padding-top: 34.48vw
    h1
      color: $titleGradientTo
      text-transform: uppercase
      font-family: Prostokvashino
      font-size: vh(6.4)
      line-height: 110%
      text-align: center
      letter-spacing: -0.03em
      margin-bottom: vh(3.2)
      @media #{$tablet}
        font-size: 6.05vw
        margin-bottom: 3.13vw
      @media #{$mobile}
        font-size: 11.88vw
        margin-bottom: 8.13vw
        order: 1
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        br
          display: none
      span
        color: inherit
        font-family: inherit
        font-size: vh(4.85)
        line-height: 100%
        @media #{$tablet}
          font-size: 4.59vw
        @media #{$mobile}
          font-size: 9.06vw
          margin-bottom: 0.63vw
    h2
      color: $titleGradientTo
      font-family: RotondaC
      font-weight: 700
      text-transform: none
      filter: drop-shadow(0px 0px vh(3.1) $white) drop-shadow(0px 0px vh(4.13) $white) drop-shadow(0px 0px vh(3.1) $white) drop-shadow(0px 0px vh(5.16) $white)
      font-size: vh(3.3)
      line-height: vh(4.95)
      text-align: center
      letter-spacing: -0.03em
      margin-bottom: vh(1.65)
      @media #{$tabletLandscape}
        filter: none
      @media #{$tablet}
        font-size: 3.13vw
        line-height: 4.69vw
        filter: none
        margin-bottom: 4.39vw
      @media #{$mobile}
        font-size: 5vw
        line-height: 7.5vw
        margin-bottom: 6vw
        max-width: 70vw
        order: 2
      b
        font-weight: inherit
        font-size: vh(4.95)
        color: inherit
        @media #{$tablet}
          font-size: 4.69vw
        @media #{$mobile}
          font-size: 7.5vw
  &-video
    position: relative
    width: vh(56.55)
    height: vh(31.79)
    margin-bottom: vh(2.06)
    z-index: 1
    visibility: visible !important
    @media #{$tablet}
      width: 53.52vw
      height: 30.08vw
      margin-bottom: 1.95vw
    @media #{$mobile}
      width: 93.75vw !important
      height: 52.5vw !important
      order: 4
    *
      visibility: visible !important
    &-badge
      position: absolute
      left: vh(-1.55)
      top: vh(-1.55)
      width: vh(17.03)
      font-size: vh(1.75)
      line-height: 130%
      color: $white
      z-index: 3
      text-align: center
      font-family: Prostokvashino
      text-transform: uppercase
      background-color: $darkBlue
      border: vh(0.31) solid #FAB500
      border-radius: vh(1.44)
      padding: vh(0.83) vh(1.03) vh(0.62) vh(1.03)
      transform: rotate(-7.44deg)
      filter: drop-shadow(6px 6px 15px rgba(0, 0, 0, 0.3))
      @media #{$tablet}
        width: 16.11vw
        left: -1.46vw
        top: -1.46vw
        font-size: 1.4vw
        border: 0.29vw solid #FAB500
        padding: 0.78vw 0.98vw 0.59vw 0.98vw
        border-radius: 1.37vw
      @media #{$mobile}
        width: 31.25vw
        left: -1.56vw
        top: -6.25vw
        font-size: 3.13vw
        border: 0.63vw solid #FAB500
        padding: 1.5vw 1.56vw 0.7vw 1.56vw
        border-radius: 2.5vw
    &-youtube,
    &-preview
      width: 100%
      height: 100%
      border: none
      display: block
    &-youtube
      position: relative
      z-index: 2
      iframe
        width: 100%
        height: 100%
    &-preview
      cursor: pointer
      position: absolute
      left: 0
      top: 0
      z-index: 1
      &-image
        @include noSelect
        width: 100%
        height: 100%
        object-fit: cover
      &-play
        @include noSelect
        position: absolute
        width: vh(7.43)
        height: vh(7.43)
        object-fit: contain
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        @media #{$tablet}
          width: 7.03vw
          height: 7.03vw
        @media #{$mobile}
          width: 12.19vw
          height: 12.19vw
  .matroskin-message
    position: relative
    transform: translateX(vh(7.64))
    width: vh(60.37)
    padding: vh(1.55) vh(1.55) vh(1.55) vh(2.58)
    left: 0
    bottom: 0
    margin-bottom: vh(3.51)
    transition: none
    @media #{$tablet}
      transform: translateX(-7vw)
      width: 58vw
      margin-bottom: 4.49vw
      padding: 1.46vw 1.2vw 1.46vw 2.5vw
    @media #{$mobile}
      transform: (translateX(-11vw))
      width: 61vw
      margin-bottom: 50.94vw
      padding: 3.13vw 0vw 3.13vw 4.5vw
      order: 3
  &-date
    position: absolute
    bottom: vh(6.09)
    left: 50%
    font-size: vh(1.24)
    line-height: 120%
    font-weight: 700
    text-shadow: 0px 0px 20px #FFFFFF, 0px 0px 20px #FFFFFF
    transform: translateX(-50%) translateX(vh(-29.21))
    @media #{$tablet}
      bottom: 6.05vw
      font-size: 1.17vw
      transform: translateX(-50%) translateX(-26.17vw)
    @media #{$mobile}
      bottom: 62vw
      font-size: 2.5vw
      transform: translateX(-50%)
      text-shadow: none
  &-button
    @include customButton
    position: relative
    z-index: 1
    @media #{$mobile}
      display: none
  &-scroll
    position: absolute
    bottom: 67.81vw
    border: none
    background: transparent
    cursor: pointer
    display: none
    @media #{$mobile}
      display: block
    img
      width: 12.5vw
      height: 12.5vw
  &-matroskin
    @include noSelect
    position: absolute
    left: 50%
    top: vh(66.75)
    transform: translateX(-50%) translateX(vh(45.41))
    width: vh(47.68)
    @media #{$tablet}
      top: 73.5vw
      transform: none
      left: unset
      right: -50px
      width: 51vw
    @media #{$mobile}
      top: 110vw
      right: -20px
      width: 53.44vw
  &-close
    @include modalCloseButton
    visibility: visible !important
    display: none
    z-index: 1
    img
      visibility: visible !important

.intro-content-tatarstan
  margin: vh(11.3) auto 0
  max-width: vh(130.03)
  background-color: #bddff6
  border-radius: 2.083vw
  padding: 4.167vw 0
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  &:before
    content: ""
    display: block
    position: absolute
    bottom: vh(-1.8)
    right: vh(-1.8)
    width: vh(29.8)
    height: vh(39.7)
    background-repeat: no-repeat
    background-size: contain
    background-image: url('/assets/img/intro-tatarstan-cat.png')
    @media #{$lg}
      width: vh(16.2)
      height: vh(21.8)
      bottom: vh(-1.1)
      right: vh(-0.8)
    @media #{$md}
      width: 20.3vw
      height: 27.1vw
      bottom: -1.2vw
      right: -1.5vw
    @media #{$mobile}
      background-image: url('/assets/img/intro-tatarstan-cat-mobile.png')
      bottom: auto
      top: 69.8vw
      bottom: auto
      width: 37.7vw
      height: 53vw
      right: -0.5vw
  @media #{$lg}
    margin-top: vh(27.7)
    padding: vh(5.9) 0
    max-width: vh(63.1)
    border-radius: vh(2.9)
  @media #{$md}
    margin-top: 21vw
    padding: 10.4vw 0
    max-width: 79.1vw
    border-radius: 5.2vw
  @media #{$mobile} 
    margin-top: 34.48vw
    padding: 0 0 10vw
    background-color: transparent
    max-width: 100%
  h1
    color: $titleGradientTo
    text-transform: uppercase
    font-family: Prostokvashino
    font-size: vh(5.21)
    line-height: 100%
    text-align: center
    margin: 0 auto vh(4.34)
    max-width: vh(69.4)
    position: relative
    filter: drop-shadow(0 vh(0.21) vh(0.52) rgba(0, 0, 0, 0.2))
    @include stroke($white, 2px)
    &:after,
    &:before
      content: ""
      display: block
      position: absolute
      bottom: vh(-2.4)
      width: vh(19.1)
      height: vh(8.4)
      background-repeat: no-repeat
      background-size: contain
      @media #{$lg}
        width: vh(8.3)
        height: vh(4)
        bottom: vh(-4)
      @media #{$md}
        width: 12.7vw
        height: 5vw
        bottom: -4.1vw
      @media #{$mobile} 
        display: none
    &:after
      background-image: url('/assets/img/intro-tatarstan-decor-right.svg')
      right: vh(-19.74)
      @media #{$lg}
        right: vh(-5.5)
      @media #{$md}
        transform: rotate(-5deg)
        right: -10vw
    &:before
      background-image: url('/assets/img/intro-tatarstan-decor-left.svg')
      left: vh(-19.74)
      @media #{$lg}
        left: vh(-5.5)
      @media #{$md}
        transform: rotate(5deg)
        left: -8.4vw
    span
      font-family: inherit
      font-size: inherit
      color: inherit
      line-height: 100%
      &.color
        color: #ff4d48
      &.desktop
        @media #{$mobile}
          display: none
    @media #{$lg}
      font-size: vh(3.5)
      filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.2))
      max-width: vh(46.7)
      margin-bottom: vh(3.5)
      @include stroke($white, 1px)
    @media #{$md}
      font-size: 4.2vw
      max-width: 55.6vw
      filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.2))
      @include stroke($white, 1px)
      margin-bottom: 6.3vw
    @media #{$mobile}
      font-size: 10vw
      filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.2))
      @include stroke($white, 1px)
      max-width: 65vw
      margin-bottom: 3.8vw
  p
    color: #000
    font-size: vh(2.61)
    line-height: 142%
    text-align: center
    margin: 0 auto vh(3.5)
    max-width: vh(91.9)
    &.mobile
      display: none
      @media #{$mobile} 
        display: block
    &.desktop
      @media #{$mobile} 
        display: none
    @media #{$lg}
      font-size: vh(1.3)
      margin-bottom: vh(4)
      max-width: vh(45.5)
      line-height: 158%
    @media #{$md}
      font-size: 2.3vw
      margin-bottom: 6.2vw
      max-width: 63.1vw
    @media #{$mobile} 
      font-weight: 700
      color: #00569F
      font-size: 5vw
      line-height: 150%
      margin-bottom: 7.5vw
      max-width: 90vw
  &-caption
    margin: 0 auto vh(8.7)
    width: vh(79.5)
    padding: vh(2.61) vh(3.2)
    background-image: url('/assets/img/intro-tatarstan-caption.png')
    background-repeat: no-repeat
    background-size: contain
    line-height: 0
    text-align: center
    @media #{$lg}
      width: vh(32.4)
      padding: vh(1.1)
      background-image: url('/assets/img/intro-tatarstan-caption-tablet.png')
      margin-bottom: vh(3.5)
    @media #{$md}
      width: 57.8vw
      padding: 2vw
      background-image: url('/assets/img/intro-tatarstan-caption-tablet.png')
      margin-bottom: 5.2vw
    @media #{$mobile}
      background-image: url('/assets/img/intro-tatarstan-caption-mobile.png')
      min-width: 67vw
      width: auto
      margin-bottom: 71.9vw
      margin-left: 6.6vw
      text-align: left
      padding: 2.5vw 3vw 2.5vw 2.5vw
      background-position: right
      position: relative
      white-space: nowrap
      border-radius: 0 3vw 0 0
      &:before 
        content: ""
        display: block
        position: absolute
        width: 50%
        height: 100%
        left: 0
        top: 0
        background-color: #3498EC
        z-index: -1
        border-radius: 3vw
    span
      font-weight: 700
      font-size: vh(2.61)
      line-height: 130%
      color: #fff
      &.mobile
        display: none
        @media #{$mobile} 
          display: block
          white-space: nowrap
      &.desktop
        @media #{$mobile} 
          display: none
      @media #{$lg}
        font-size: vh(1.3)
        line-height: 161%
      @media #{$md}
        font-size: 2.35vw
        line-height: 161%
      @media #{$mobile} 
        font-size: 3.44vw
        line-height: 130%
    &-mobile
      display: none
      @media #{$mobile}
        display: block
        background-color: #BDDFF6
        width: 87.5vw
        border-radius: 7.5vw
        padding: 5vw 8.8vw 22.5vw 5vw
        color: #000
        font-size: 3.5vw
        line-height: 128%
        position: relative

        &:after,
        &:before
          content: ""
          display: block
          position: absolute
          bottom: 2vw
          width: 37vw
          height: 19vw
          background-repeat: no-repeat
          background-size: contain
        &:after
          background-image: url('/assets/img/intro-tatarstan-decor-right.svg')
          right: 5vw
        &:before
          background-image: url('/assets/img/intro-tatarstan-decor-left.svg')
          left: 5vw
        img
          position: absolute
          height: auto
          z-index: 1
          &:first-of-type
            width: 35vw
            top: 14vw
            right: -3vw
          &:last-of-type
            width: 12vw
            right: 21vw
            top: 24vw
  &-button
    color: $white
    font-size: vh(1.96)
    line-height: vh(2.89)
    font-weight: 700
    padding: vh(2.12) vh(6.51)
    border-radius: vh(4.64)
    background-color: $darkBlue
    border: none
    cursor: pointer
    @media #{$lg}
      font-size: vh(1.3)
      padding: 0
      width: vh(13.9)
      height: vh(4.3)
    @media #{$md}
      font-size: 2.3vw
      padding: 0
      width: 23vw
      height: 7.8vw
    @media #{$mobile}
      display: none
  &-close
    position: absolute
    right: vh(-1.31)
    top: vh(-1.74)
    line-height: 0
    background-color: transparent
    border: none
    padding: 0
    margin: 0
    width: vh(5.21)
    cursor: pointer
    img
      width: 100%
      height: auto
    @media #{$lg}
      width: vh(3.5)
      height: vh(3.5)
      right: vh(-0.8)
      top: vh(-1.1)
    @media #{$md}
      width: 6.25vw
      height: 6.25vw
      right: -1.5vw
      top: -2vw
    @media #{$mobile} 
      display: none
  &-info
    position: absolute
    bottom: vh(-12)
    left: 50%
    transform: translateX(-50%)
    color: #000
    font-weight: 700
    text-align: center
    font-size: vh(1.52)
    line-height: 130%
    @media #{$lg}
      bottom: vh(-22)
      font-size: vh(1)
    @media #{$md}
      font-size: 1.8vw
      bottom: -28vw
    @media #{$mobile}
      width: 100%
      padding: 0 6.25vw
      font-size: 2.5vw
      line-height: 120%
      bottom: auto
      top: 132vw
  &-icon
    position: absolute
    left: vh(-3.1)
    bottom: vh(27.6)
    width: vh(10.3)
    height: auto
    @media #{$lg}
      width: vh(7)
      bottom: vh(15.2)
      left: vh(-3.1)
    @media #{$md}
      width: 7.6vw
      bottom: 20vw
      left: -5vw
    @media #{$mobile}
      top: 88vw
      left: 12vw
      width: 12vw
      bottom: auto
  &-bottle
    &-1
      position: absolute
      left: vh(-3.3)
      bottom: vh(0.5)
      width: auto
      height: vh(54.6)
      @media #{$lg}
        height: vh(31.4)
        bottom: 0
        left: vh(-2.5)
      @media #{$md}
        height: 38.9vw
        left: -4.8vw
        bottom: 1vw
      @media #{$mobile}
        height: 56vw
        left: 4vw
        top: 76vw
        transform: rotate(-7deg)
    &-2
      position: absolute
      left: vh(10.4)
      bottom: vh(-5.4)
      width: vh(28)
      height: auto
      @media #{$lg}
        width: vh(15.8)
        left: vh(5.1)
        bottom: vh(-3.1)
      @media #{$md}
        width: 21.2vw
        left: 4.9vw
        bottom: -3.4vw
      @media #{$mobile}
        display: none
  .intro-scroll
    bottom: 80vw