.care-game-overlay
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center
  z-index: 2

.care-game-content
  display: flex
  flex-direction: column
  align-items: center
  z-index: 1
  @media #{$tablet}
    width: tpx(720)
  @media #{$mobile}
    width: mpx(320)
    height: 150%
    position: relative
  &--options
    align-items: center
    justify-content: flex-start
    width: 100%
    height: 100%
    padding-top: dpx(244)
    @media #{$tablet}
      padding-top: tpx(429)
    @media #{$mobile}
      padding-top: mpx(172)
      padding-bottom: 15vw
      overflow-y: auto
  &-cow
    position: absolute
    top: dpx(252)
    width: dpx(720)
    height: dpx(411)
    @include noSelect
    @media #{$tablet}
      top: tpx(420)
      width: tpx(720)
      height: tpx(411)
    @media #{$mobile}
      top: mpx(162)
      width: mpx(320)
      height: mpx(182)
  &--progress
    position: absolute
    width: dpx(471)
    height: dpx(33)
    top: dpx(166)
    border-radius: dpx(16)
    @media #{$tablet}
      width: tpx(471)
      height: tpx(33)
      top: tpx(328)
      border-radius: tpx(16)
    @media #{$mobile}
      width: mpx(232)
      height: mpx(16)
      top: mpx(130)
      border-radius: mpx(15)
    progress
      -webkit-appearance: none
      appearance: none
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
    progress[value]::-webkit-progress-value
      background: linear-gradient(360deg, $blue 0%, rgba(48, 149, 235, 0.3) 100%)
      border-radius: dpx(16) dpx(7) dpx(7) dpx(16)
      @media #{$tablet}
        border-radius: tpx(16) tpx(7) tpx(7) tpx(16)
      @media #{$mobile}
        position: relative
        left: mpx(-1)
        height: mpx(14)
        border-radius: mpx(15)
    progress[value]::-webkit-progress-bar
      background-color: $white
      border: dpx(1.6) solid $blue
      border-radius: dpx(16)
      width: dpx(471)
      @media #{$tablet}
        border: tpx(1.6) solid $blue
        border-radius: tpx(16)
        width: tpx(471)
      @media #{$mobile}
        border: mpx(1.5) solid $blue
        border-radius: mpx(15)
        width: mpx(232)
    progress::-moz-progress-bar
      background-color: $white
      border: dpx(1.6) solid $blue
      border-radius: dpx(16)
      width: dpx(471)
      @media #{$tablet}
        border: tpx(1.6) solid $blue
        border-radius: tpx(16)
        width: tpx(471)
      @media #{$mobile}
        border: mpx(1.5) solid $blue
        border-radius: mpx(15)
        width: mpx(232)
    &-line 
      position: absolute
      left: 50%
      top: dpx(5)
      background: $white
      border-radius: dpx(16)
      width: dpx(447)
      height: dpx(10)
      transform: translateX(-50%)
      opacity: 0.5
      @media #{$tablet}
        top: tpx(5)
        border-radius: tpx(16)
        width: tpx(447)
        height: tpx(10)
      @media #{$mobile}
        top: mpx(2.5)
        border-radius: mpx(15)
        width: mpx(220)
        height: mpx(5)
      &-gray
        top: dpx(23)
        background: $black
        opacity: 0.2
        height: dpx(4)
        @media #{$tablet}
          top: tpx(23)
          height: tpx(4)
        @media #{$mobile}
          top: mpx(11)
          height: mpx(2)
    &-star-icon
      position: absolute
      top: dpx(-18)
      left: dpx(24)
      width: dpx(62)
      height: dpx(59)
      transform: translateX(-50%)
      @include noSelect
      @media #{$tablet}
        top: tpx(-18)
        left: tpx(24)
        width: tpx(62)
        height: tpx(59)
      @media #{$mobile}
        width: mpx(30)
        height: mpx(29)
        top: mpx(-9)
        left: mpx(12)
  &--categories
    position: absolute
    top: dpx(675)
    display: flex
    justify-content: space-between
    max-width: dpx(475)
    width: dpx(475)
    @media #{$tablet}
      top: tpx(854)
      max-width: tpx(475)
      width: tpx(475)
    @media #{$mobile}
      top: mpx(358)
      max-width: mpx(280)
      width: mpx(280)
    &-button 
      display: flex
      flex-direction: column
      justify-content: flex-end
      align-items: center
      background: $white
      border: dpx(2) solid $blue
      border-radius: dpx(20)
      width: dpx(100)
      height: dpx(115)
      padding-bottom: dpx(16)
      cursor: pointer
      @media #{$tablet}
        border: tpx(2) solid $blue
        border-radius: tpx(20)
        width: tpx(100)
        height: tpx(115)
        padding-bottom: tpx(16)
      @media #{$mobile}
        border: mpx(1.7) solid $blue
        border-radius: mpx(13)
        width: mpx(64)
        height: mpx(81)
        padding-bottom: mpx(11)
      &:disabled
        pointer-events: none
      &.incorrect
        background: $white !important
        box-shadow: 0 0 dpx(50) $corall
        p
          color: $blue !important
        @media #{$tablet}
          box-shadow: 0 0 tpx(50) $corall
        @media #{$mobile}
          box-shadow: 0 0 mpx(20) $corall
      &:hover
        background: $blue
        @media #{$tablet}
          background: $white
        p
          color: $white
          @media #{$tablet}
            color: $blue
    &-close
      @include modalCloseButton
    &-icon 
      max-width: dpx(75)
      min-width: dpx(56)
      max-height: dpx(58)
      min-height: dpx(45)
      margin: auto
      @include noSelect
      @media #{$tablet}
        max-width: tpx(75)
        min-width: tpx(56)
        max-height: tpx(58)
        min-height: tpx(45)
      @media #{$mobile}
        max-width: mpx(49)
        min-width: mpx(40)
        max-height: mpx(43)
        min-height: mpx(31)
    &-title
      color: $blue
      font-size: dpx(20)
      line-height: dpx(23)
      font-weight: 700
      @media #{$tablet}
        font-size: tpx(20)
        line-height: tpx(23)
      @media #{$mobile}
        font-size: mpx(10)
        line-height: mpx(12)
        letter-spacing: -0.02em
  &--button-home
    position: fixed
    bottom: dpx(40)
    width: dpx(72)
    background: none
    border: none
    cursor: pointer
    z-index: 1
    &:disabled
      opacity: 0.35
      cursor: not-allowed
    @media #{$tablet}
      width: tpx(72)
      bottom: tpx(58)
    @media #{$mobile}
      width: mpx(49)
      height: mpx(49)
      bottom: mpx(15)
    img
      display: block 
      max-width: 100%
      @include noSelect
  &--question
    position: relative
    background: transparentize($white, 0.05)
    width: dpx(588)
    max-height: dpx(645)
    padding: dpx(50) dpx(49) dpx(54)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    z-index: 2
    @media #{$tablet}
      width: tpx(588)
      max-height: tpx(645)
      padding: tpx(50) tpx(49) tpx(54)
      border-radius: tpx(40) 0 tpx(40) tpx(40)
    @media #{$mobile}
      width: mpx(268)
      max-height: mpx(346)
      padding: mpx(30) mpx(17) mpx(22)
      border-radius: mpx(25) 0 mpx(25) mpx(25)
    &-title 
      color: $blue
      font-size: dpx(40)
      line-height: dpx(52)
      font-weight: 700
      text-align: center
      width: dpx(525)
      margin-left: calc( (dpx(490) - dpx(525) ) / 2)
      margin-bottom: dpx(39)
      @media #{$tablet}
        font-size: tpx(40)
        line-height: tpx(52)
        width: tpx(525)
        margin-left: calc( (tpx(490) - tpx(525) ) / 2)
        margin-bottom: tpx(39)
      @media #{$mobile}
        font-size: mpx(20)
        line-height: mpx(26)
        width: mpx(240)
        margin-left: calc( (mpx(234) - mpx(240) ) / 2)
        margin-bottom: mpx(29)
    &-buttons
      display: flex
      justify-content: space-between
    &-button
      display: flex
      flex-direction: column
      justify-content: flex-end
      align-items: center
      background-color: $white
      border: dpx(4) solid $blue
      border-radius: dpx(30)
      width: dpx(150)
      height: dpx(192)
      padding-bottom: dpx(17)
      cursor: pointer
      &:disabled
        pointer-events: none
      &:hover
        background: $blue
        @media #{$tablet}
          background: $white
        p
          color: $white
          @media #{$tablet}
            color: $blue
      @media #{$tablet}
        border: tpx(4) solid $blue
        border-radius: tpx(30)
        width: tpx(150)
        height: tpx(192)
        padding-bottom: tpx(17)
      @media #{$mobile}
        border: mpx(2) solid $blue
        border-radius: mpx(15)
        width: mpx(75)
        height: mpx(96)
        padding-bottom: mpx(8)
      img
        max-width: dpx(116)
        min-width: dpx(76)
        max-height: dpx(118)
        min-height: dpx(79)
        @include noSelect
        @media #{$tablet}
          max-width: tpx(116)
          min-width: tpx(76)
          max-height: tpx(118)
          min-height: tpx(79)
        @media #{$mobile}
          max-width: mpx(58)
          min-width: mpx(40)
          max-height: mpx(64)
          min-height: mpx(40)
      p
        color: $blue
        font-size: dpx(20)
        line-height: dpx(23)
        font-weight: 700
        @media #{$tablet}
          font-size: tpx(20)
          line-height: tpx(23)
        @media #{$mobile}
          font-size: mpx(10)
          line-height: mpx(12)
    .chosen
      &-correct
        background-color: $green
        border-color: $green
        p
          color: $white
      &-incorrect
        box-shadow: 0 0 dpx(50) $corall
        @media #{$tablet}
          box-shadow: 0 0 tpx(50) $corall
        @media #{$mobile}
          box-shadow: 0 0 mpx(20) $corall
  &--result
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    display: flex
    align-items: flex-start
    justify-content: center
    padding-top: dpx(182)
    @media #{$tablet}
      padding-top: 0
      align-items: center
    @media #{$mobile}
      align-items: flex-start
      padding-top: mpx(137)
    &--blur
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: transparentize($white, 0.65)
      backdrop-filter: blur(2.5px)
    &-content
      width: dpx(520)
      height: auto
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      background: transparentize($white, 0.075)
      border-radius: dpx(40) 0 dpx(40) dpx(40)
      padding: dpx(36) dpx(20) dpx(64) dpx(28)
      position: relative
      @media #{$tablet}
        width: tpx(520)
        border-radius: tpx(40) 0 tpx(40) tpx(40)
        padding: tpx(36) tpx(20) tpx(64) tpx(28)
      @media #{$mobile}
        width: mpx(268)
        border-radius: mpx(21) 0 mpx(21) mpx(21)
        padding: mpx(19) mpx(10) mpx(33) mpx(14)
    &-image
      width: dpx(432)
      height: dpx(258)
      display: block
      object-fit: cover
      @include noSelect
      @media #{$tablet}
        width: tpx(432)
        height: tpx(258)
      @media #{$mobile}
        width: mpx(222)
        height: mpx(133)
    &-title
      color: $blue
      text-transform: uppercase
      font-family: Prostokvashino
      text-align: center
      letter-spacing: -0.03em
      font-size: dpx(60)
      line-height: dpx(54)
      letter-spacing: -0.03em
      word-spacing: dpx(-10)
      filter: drop-shadow(0 dpx(2) dpx(3) rgba(0, 0, 0, 0.13))
      margin-bottom: dpx(13)
      @include stroke($white, 2px)
      @media #{$tablet}
        font-size: tpx(60)
        line-height: tpx(54)
        word-spacing: tpx(-10)
        filter: drop-shadow(0 tpx(2) tpx(5) rgba(0, 0, 0, 0.2))
        margin-bottom: tpx(13)
      @media #{$mobile}
        font-size: mpx(32)
        line-height: mpx(29)
        word-spacing: mpx(-7)
        filter: drop-shadow(0 tpx(1) tpx(3) rgba(0, 0, 0, 0.2))
        margin-bottom: mpx(7)
        @include stroke($white, 1px)
    &-button
      @include modalButton
      width: dpx(250)
      height: dpx(80)
      @media #{$tablet}
        width: tpx(250)
        height: tpx(80)
      @media #{$mobile}
        width: mpx(136)
        height: mpx(40)
    &-close
      @include modalCloseButton
  br 
    @media #{$mobile}
      display: none
  .br-mobile
    display: none
    @media #{$mobile}
      display: block
