.loader
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: calc(100 * var(--vh))
  display: flex
  align-items: center
  justify-content: center
  background-color: $white
  z-index: 150
  opacity: 1
  transition: opacity .3s
  &--hidden
    opacity: 0
    pointer-events: none
  p, span
    color: $blue
    text-transform: uppercase
    font-family: Prostokvashino
    text-align: center
    letter-spacing: -0.03em
    font-size: vh(6.5)
    line-height: 90%
    filter: drop-shadow(0 vh(0.21) vh(0.52) rgba(0, 0, 0, 0.2))
    @include stroke($white, 2px)
    @media #{$tablet}
      font-size: 6.15vw
      filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.2))
    @media #{$mobile}
      font-size: 10vw
      filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.2))
      @include stroke($white, 1px)
  p
    margin-right: vh(0.5)
    @media #{$tablet}
      margin-right: 0.5vw
    @media #{$mobile}
      margin-right: 1vw
  span
    opacity: 0
    &:nth-child(2)
      animation: loaderFirstDot 1.5s infinite
    &:nth-child(3)
      animation: loaderSecondDot 1.5s infinite
    &:nth-child(4)
      animation: loaderThirdDot 1.5s infinite
