@import './functions'
@import './vars'
@import './mixin'
@import './animation'

*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: RotondaC
  text-decoration: none
  font-weight: 400
  color: $black
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

html, body
  font-size: 20px
  overflow: hidden

.application
  width: 100vw
  height: calc(100 * var(--vh))
  touch-action: none
  -webkit-touch-action: none
  overflow: hidden
  position: fixed
  @media #{$mobile}
    overflow-x: hidden
    overflow-y: scroll
    touch-action: unset
    -webkit-touch-action: unset
    &--noscroll
      overflow-y: hidden

@import './components/care/care'
@import './components/intro'
@import './components/navbar'
@import './components/loader'
@import './components/quality'
@import './components/workers'
@import './components/infospot'
@import './components/panorama'
@import './components/matroskin'
@import './components/background'
@import './components/info-modal'
@import './components/geolocation'
@import './components/dialog-modal'
@import './components/404'
@import './components/academy/academy'
