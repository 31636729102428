.dialog-modal
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  @include whiteBlur
  @media #{$mobile}
    align-items: flex-start
    padding-top: mpx(106)
    padding-bottom: 15vw
    overflow-y: auto
  &-content
    width: dpx(610)
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    padding: dpx(30) dpx(35) dpx(40) dpx(35)
    position: relative
    @media #{$tablet}
      width: tpx(610)
      border-radius: tpx(40) 0 tpx(40) tpx(40)
      padding: tpx(30) tpx(35) tpx(40) tpx(35)
    @media #{$mobile}
      width: mpx(268)
      border-radius: mpx(25) 0 mpx(25) mpx(25)
      padding: mpx(15) mpx(15) mpx(18) mpx(15)
  &-title
    font-size: dpx(36)
    font-weight: 700
    line-height: 90%
    color: $blue
    margin-bottom: dpx(25)
    text-align: center
    @media #{$tablet}
      font-size: tpx(36)
      margin-bottom: tpx(25)
    @media #{$mobile}
      font-size: mpx(20)
      margin-bottom: mpx(15)
  &-messages
    width: 100%
  &-message
    width: 100%
    display: flex
    align-items: flex-start
    justify-content: flex-start
    margin-bottom: dpx(25)
    column-gap: dpx(16)
    @media #{$tablet}
      margin-bottom: tpx(25)
      column-gap: tpx(16)
    @media #{$mobile}
      margin-bottom: mpx(16)
      column-gap: mpx(9)
    &:nth-last-child(1)
      margin-bottom: 0
    &-image
      position: relative
      width: dpx(102)
      height: dpx(102)
      flex: none
      border: dpx(5) solid $blue
      border-radius: 50%
      order: 2
      overflow: hidden
      @media #{$tablet}
        width: tpx(102)
        height: tpx(102)
        border-width: tpx(5)
      @media #{$mobile}
        width: mpx(51)
        height: mpx(51)
        border-width: mpx(2)
      img
        position: absolute
        width: dpx(102)
        height: dpx(102)
        object-fit: cover
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        @include noSelect
        @media #{$tablet}
          width: tpx(102)
          height: tpx(102)
        @media #{$mobile}
          width: mpx(51)
          height: mpx(51)
    .matroskin-message
      width: 100%
      left: 0
      top: 0
      bottom: unset
      position: relative
      order: 3
      padding-top: dpx(25)
      padding-bottom: dpx(25)
      @media #{$tablet}
        padding-top: tpx(25)
        padding-bottom: tpx(25)
      @media #{$mobile}
        padding-top: mpx(10)
        padding-bottom: mpx(10)
      p
        font-size: dpx(20)
        letter-spacing: -0.03em
        @media #{$tablet}
          font-size: tpx(20)
        @media #{$mobile}
          font-size: mpx(12)
    &:nth-child(odd)
      margin-left: dpx(-5)
      @media #{$tablet}
        margin-left: tpx(-5)
      @media #{$mobile}
        margin-left: mpx(-1)
      .matroskin-message
        padding-right: dpx(35)
        padding-left: 0
        transform: scale(-1, -1)
        @media #{$tablet}
          padding-right: tpx(35)
        @media #{$mobile}
          padding-right: mpx(10)
          padding-left: mpx(5)
        p
          transform: scale(-1, -1)
    &:nth-child(even)
      margin-left: dpx(5)
      @media #{$tablet}
        margin-left: tpx(5)
      @media #{$mobile}
        margin-left: mpx(1)
      .dialog-modal-message-image
        border-color: $darkBlue
      .matroskin-message
        padding-right: 0
        padding-left: dpx(35)
        background-color: $darkBlue
        transform: scale(1, -1)
        order: 1
        @media #{$tablet}
          padding-left: tpx(35)
        @media #{$mobile}
          padding-left: mpx(15)
        .matroskin-message-pad
          opacity: 0
          &--dark
            opacity: 1
        p
          transform: scale(1, -1)
  &-close
    @include modalCloseButton
