.care-tab
  &-tutorial
    display: flex
    justify-content: center
    width: 100%
    height: 100%
    padding: dpx(177) 0 0 0 
    z-index: 2
    @media #{$tablet}
      padding-top: tpx(317)
    @media #{$mobile}
      padding-top: mpx(126)
      padding-bottom: 15vw
      overflow-y: auto
    .care-tab-content
      height: dpx(683)
      @media #{$tablet}
        height: tpx(683)
      @media #{$mobile}
        height: mpx(363)
    &-title
      color: $blue
      text-transform: uppercase
      font-family: Prostokvashino
      text-align: center
      letter-spacing: -0.03em
      font-size: dpx(64)
      line-height: dpx(64)
      filter: drop-shadow(0 vh(0.23) vh(0.20) rgba(0, 0, 0, 0.1))
      word-spacing: dpx(-19)
      margin-bottom: dpx(5)
      @include stroke($white, 2px)
      @media #{$tablet}
        font-size: tpx(64)
        line-height: tpx(64)
        word-spacing: tpx(-19)
        filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.2))
        margin-bottom: tpx(5)
      @media #{$mobile}
        font-size: mpx(32)
        line-height: 90%
        word-spacing: mpx(-10)
        filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.2))
        margin-bottom: mpx(13)
        @include stroke($white, 1px)
    &-image
      display: block
      width: dpx(522)
      height: dpx(299)
      object-fit: contain
      @include noSelect
      @media #{$tablet}
        width: tpx(522)
        height: tpx(299)
      @media #{$mobile}
        width: mpx(228)
        height: mpx(130)
    .matroskin-message
      left: dpx(582)
      bottom: dpx(92)
      width: dpx(456)
      padding: dpx(20) dpx(15) dpx(20) dpx(30)
      @media #{$tablet}
        left: tpx(582)
        bottom: tpx(92)
        width: tpx(456)
        padding: tpx(20) tpx(15) tpx(20) tpx(30)
      @media #{$mobile}
        left: mpx(197)
        bottom: mpx(44)
        width: mpx(162)
        padding: mpx(10) mpx(0) mpx(10) mpx(15)
      p
        @media #{$mobile}
          line-height: mpx(16)
    &-matroskin
      position: absolute
      right: 0
      bottom: 0
      width: dpx(237)
      height: dpx(246)
      overflow: hidden
      @include noSelect
      @media #{$tablet}
        width: tpx(237)
        height: tpx(246)
        border-radius: 0 tpx(40) 0 0
      @media #{$mobile}
        width: mpx(77)
        height: mpx(94)
      img
        display: block
        width: dpx(247)
        height: dpx(395)
        @media #{$tablet}
          width: tpx(247)
          height: tpx(395)
        @media #{$mobile}
          width: mpx(94)
          height: mpx(149)
  .mobile
    display: none
    @media #{$mobile}
      display: block
