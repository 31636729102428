.academy-tab
  &-result
    width: vh(62.95)
    padding: vh(7.64) 0 vh(9.6) 0
    margin-top: vh(20)
    @media #{$tablet}
      width: 59.57vw
      padding: 7.23vw 0 9.08vw 0
      margin-top: auto
      margin-bottom: auto
    @media #{$mobile}
      width: 83.75vw
      padding: 12.5vw 0 13.75vw 0
    .academy-tab-button
      @media #{$mobile}
        white-space: nowrap
    &-title
      color: $blue
      text-transform: uppercase
      font-family: Prostokvashino
      text-align: center
      font-size: vh(8.46)
      line-height: 90%
      filter: drop-shadow(0 vh(0.21) vh(0.52) rgba(0, 0, 0, 0.2))
      margin-bottom: vh(0.72)
      @include stroke($white, 1px)
      @media #{$tablet}
        font-size: 8.01vw
        margin-bottom: 0.68vw
        filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.1))
      @media #{$mobile}
        font-size: 10.63vw
        margin-bottom: 0.94vw
        filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.1))
    &-count
      color: $blue
      text-align: center
      font-size: vh(8.46)
      font-weight: 700
      line-height: 130%
      margin-bottom: vh(2.68)
      @media #{$tablet}
        font-size: 8.01vw
        margin-bottom: 2.44vw
      @media #{$mobile}
        font-size: 10.63vw
        margin-bottom: 3.13vw
    &-description
      font-size: vh(3.3)
      line-height: 130%
      font-weight: 700
      text-align: center
      @media #{$tablet}
        font-size: 3.13vw
      @media #{$mobile}
        font-size: 4.38vw
      br
        @media #{$mobile}
          display: none
        &.mobile
          display: none
          @media #{$mobile}
            display: block
    &-promocode
      font-size: vh(4.95)
      font-weight: 700
      line-height: 110%
      letter-spacing: 0.05em
      color: $blue
      text-transform: uppercase
      cursor: pointer
      text-align: center
      margin-top: vh(1.24)
      @media #{$tablet}
        font-size: 4.69vw
        margin-top: 1.17vw
      @media #{$mobile}
        font-size: 7.5vw
        margin-top: 2.81vw
    &-promotext
      font-size: vh(2.48)
      font-weight: 700
      line-height: 130%
      text-align: center
      margin-top: vh(0.52)
      @media #{$tablet}
        font-size: 2.34vw
        margin-top: 0.49vw
      @media #{$mobile}
        font-size: 3.75vw
        margin-top: 1vw
      a
        font-size: inherit
        font-weight: inherit
        color: $blue
        text-decoration: underline
    &-rules
      font-size: vh(2.48)
      font-weight: 700
      line-height: 130%
      text-align: center
      margin-top: vh(1.5)
      text-decoration: underline
      @media #{$tablet}
        font-size: 2.34vw
        margin-top: 1vw
      @media #{$mobile}
        font-size: 3.75vw
        margin-top: 1.5vw
