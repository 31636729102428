.care-tab
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center
  background-color: transparentize($white, 0.65)
  backdrop-filter: blur(2.5px)
  &-background
    --scale: 1
    --scale-tablet: 1
    --scale-mobile: 1
    width: 100%
    height: 100%
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%) scale(var(--scale))
    overflow: hidden
    z-index: 1
    &--not-opacity
      opacity: 0
    img 
      position: relative
      display: block
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      opacity: 0.8
      @include noSelect
    &--opacity-block
      position: absolute
      display: block
      top: 0
      left: 0
      background: $white
      width: 100%
      height: 100%
      object-fit: cover
  &-content
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: linear-gradient(118deg, #FEFFFF 2%, #F6FBFF 98%)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    width: dpx(810)
    height: auto
    padding: dpx(45) 0 dpx(269) 0
    opacity: 0.95
    @media #{$tablet}
      width: tpx(810)
      padding: tpx(45) 0 tpx(269) 0
      border-radius: tpx(40) 0 tpx(40) tpx(40)
    @media #{$mobile}
      max-width: 100%
      width: mpx(268)
      border-radius: 7.81vw 0 7.81vw 7.81vw
      padding: mpx(18) 0 mpx(144) 0
  &-close
    @include modalCloseButton
  &-button
    @include modalButton
    width: dpx(237)
    @media #{$tablet}
      width: tpx(237)
    @media #{$mobile}
      width: mpx(109)
      padding: mpx(14) mpx(34) mpx(12) mpx(34)
    &--big
      width: vh(31.99)
      @media #{$tablet}
        width: 30.27vw
      @media #{$mobile}
        width: 56.5vw

@import './care-tutorial'
@import './care-game'
