.academy-tab
  &-game
    width: vh(91.95)
    padding: vh(4.13) vh(4.13) vh(7.33) vh(4.13)
    margin-top: vh(19.81)
    align-items: flex-start
    @media #{$tablet}
      width: 87.01vw
      padding: 3.91vw 3.91vw 6.93vw 3.91vw
      margin-top: 29.88vw
    @media #{$mobile}
      width: 83.75vw
      padding: 6.25vw 6.25vw 13.13vw 6.25vw
      margin-top: 29.06vw
    br
      @media #{$mobile}
        display: none
      &.mobile
        display: none
        @media #{$mobile}
          display: block
    &-preview
      display: block
      width: vh(30.86)
      height: vh(21.78)
      object-fit: cover
      border-radius: vh(2.06)
      margin-bottom: vh(3.61)
      @include noSelect
      @media #{$tablet}
        width: 29.2vw
        height: 20.61vw
        border-radius: 1.95vw
        margin-bottom: 3.42vw
      @media #{$mobile}
        width: 48.13vw
        height: 31.25vw
        border-radius: 4.06vw
        margin-bottom: 5.94vw
    &-count
      position: absolute
      top: vh(4.95)
      left: vh(38.49)
      font-size: vh(4.13)
      font-weight: 700
      color: $blue
      @media #{$tablet}
        top: 4.1vw
        left: 36.52vw
        font-size: 3.91vw
      @media #{$mobile}
        top: 15.63vw
        left: 58.13vw
        font-size: 6.25vw
        max-width: 21.88vw
    &-text
      position: absolute
      top: vh(13.75)
      left: vh(38.8)
      font-size: vh(2.48)
      line-height: 130%
      font-weight: 700
      color: $blue
      max-width: vh(46.65)
      @media #{$tablet}
        top: 12.99vw
        left: 36.82vw
        font-size: 2.34vw
        max-width: 44.14vw
      @media #{$mobile}
        position: relative
        top: 0
        left: 0
        text-align: center
        font-size: 4.38vw
        max-width: unset
        margin-bottom: 6.25vw
        width: calc(100% + 12.5vw)
        height: 22.5vw
        margin-left: -6.25vw
        display: flex
        align-items: center
        justify-content: center
    &-answers
      width: 100%
      list-style-type: none
      button
        width: 100%
        border-radius: vh(4.64)
        background-color: $blue
        border: 0.31vw solid $blue
        display: flex
        align-items: center
        justify-content: center
        font-size: vh(2.48)
        font-weight: 700
        color: $white
        cursor: pointer
        height: vh(7.64)
        margin-bottom: vh(2.06)
        padding-top: dpx(4)
        @media #{$tablet}
          padding-top: tpx(4)
          border-radius: 4.39vw
          border-width: 0.29vw
          font-size: 2.34vw
          height: 7.23vw
          margin-bottom: 1.95vw
        @media #{$mobile}
          padding-top: 0
          border-radius: 7.81vw
          border-width: 0.63vw
          font-size: 3.75vw
          height: 12.5vw
          margin-bottom: 4.06vw
          letter-spacing: -0.02em
        &.chosen
          background-color: $white
          color: $blue
          &.incorrect
            background-color: $corall
            border-color: $corall
            color: $white
        &.correct
          background-color: $green
          border-color: $green
          color: $white
      li
        &:nth-last-child(1)
          button
            margin-bottom: 0 !important
