$mobile: "only screen and (max-width: 600px) and (orientation: portrait)"
$mobileLandscape: "only screen and (max-width: 900px) and (orientation: landscape)"
$tablet: "only screen and (max-width: 1200px) and (orientation: portrait)"
$tabletLandscape: "only screen and (max-width: 1400px) and (orientation: landscape)"
$desktop: "only screen and (min-width: 1440px)"
$lg: "only screen and (max-width: 1279px)"
$md: "only screen and (max-width: 1023px)"

$white: #FFFFFF
$black: #383B3E
$black50: transparentize($black, 0.5)
$grey: #CBCDD1
$grey50: transparentize($grey, 0.5)
$blue: #3095EB
$blue50: transparentize($blue, 0.5)
$darkBlue: #0051A2
$darkBlue50: transparentize($darkBlue, 0.5)
$green: #2DAB5F
$green50: transparentize($green, 0.5)
$corall: #FF4D48
$corallLight: transparentize($corall, 0.3)

$titleGradientFrom: #3174B3
$titleGradientTo: #0151A1
$titleGradient: linear-gradient(180deg, $titleGradientFrom 0%, $titleGradientTo 80%)
$screenBlueGradient: linear-gradient(118.31deg, #FEFFFF 1.71%, #F6FBFF 98.12%)
$screenBlueInverseGradient: linear-gradient(299.77deg, #FEFFFF 0.4%, #F6FBFF 96.93%)
