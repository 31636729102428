.academy-tab
  &-tutorial
    width: vh(56.14)
    padding: vh(4.85) 0 vh(7.74) 0
    margin-top: vh(23.32)
    @media #{$tablet}
      width: 53.13vw
      padding: 4.59vw 0 7.32vw 0
      margin-top: 42.09vw
    @media #{$mobile}
      width: 83.75vw
      padding: 11.25vw 0 13.75vw 0
      margin-top: 49.38vw
    &-title
      color: $blue
      text-transform: uppercase
      font-family: Prostokvashino
      text-align: center
      letter-spacing: -0.03em
      font-size: vh(6.6)
      line-height: 90%
      filter: drop-shadow(0 vh(0.21) vh(0.52) rgba(0, 0, 0, 0.2))
      margin-bottom: vh(4.33)
      @include stroke($white, 1px)
      @media #{$tablet}
        font-size: 6.25vw
        margin-bottom: 4.1vw
        filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.1))
      @media #{$mobile}
        font-size: 10vw
        margin-bottom: 7.5vw
        filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.1))
    &-image
      display: block
      width: vh(30.34)
      height: vh(16.92)
      object-fit: contain
      margin-bottom: vh(4.23)
      @include noSelect
      @media #{$tablet}
        width: 28.71vw
        height: 16.02vw
        margin-bottom: 4vw
      @media #{$mobile}
        width: 56.25vw
        height: 31.25vw
        margin-bottom: 9.38vw
        transform: translateX(1.88vw)
    &-description
      font-size: vh(2.48)
      line-height: 130%
      font-weight: 700
      text-align: center
      @media #{$tablet}
        font-size: 2.34vw
      @media #{$mobile}
        font-size: 3.75vw
    &-subdescription
      font-size: vh(4.13)
      line-height: 100%
      font-weight: 700
      text-align: center
      color: $blue
      @media #{$tablet}
        font-size: 3.91vw
      @media #{$mobile}
        font-size: 6.25vw
