.info-modal
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  @media #{$mobile}
    align-items: flex-start
    padding-top: 31.25vw
    padding-bottom: 15vw
    overflow-y: auto
  &--blur
    @include whiteBlur
  &-content
    width: vh(62.95)
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: vh(4.13) 0 vh(4.13) vh(4.13)
    padding: vh(3.1) vh(3.1) vh(6.71) vh(3.1)
    position: relative
    @media #{$tablet}
      width: 59.57vw
      border-radius: 3.91vw 0 3.91vw 3.91vw
      padding: 2.93vw 2.93vw 6.35vw 2.93vw
    @media #{$mobile}
      width: 83.75vw
      border-radius: 7.81vw 0 7.81vw 7.81vw
      padding: 5.63vw 5.63vw 10.63vw 5.63vw
  &-image
    width: 100%
    height: vh(32.51)
    border-radius: vh(2.06) vh(2.06) 0 0
    display: block
    margin-bottom: vh(2.58)
    object-fit: cover
    background-color: $grey
    @include noSelect
    @media #{$tablet}
      height: 30.76vw
      border-radius: 1.95vw 1.95vw 0 0
      margin-bottom: 2.44vw
    @media #{$mobile}
      height: 48.44vw
      border-radius: 4.69vw 4.69vw 0 0
      margin-bottom: 5.31vw
  &-title
    color: $blue
    text-transform: uppercase
    font-family: Prostokvashino
    text-align: center
    letter-spacing: -0.03em
    font-size: vh(5.99)
    line-height: 90%
    filter: drop-shadow(0 vh(0.21) vh(0.52) rgba(0, 0, 0, 0.2))
    margin-bottom: vh(1.03)
    @include stroke($white, 2px)
    @media #{$tablet}
      font-size: 5.66vw
      filter: drop-shadow(0 0.2vw 0.49vw rgba(0, 0, 0, 0.2))
      margin-bottom: 0.98vw
    @media #{$mobile}
      font-size: 10vw
      filter: drop-shadow(0 0.63vw 1.56vw rgba(0, 0, 0, 0.2))
      margin-bottom: 3.13vw
      @include stroke($white, 1px)
  &-text
    font-size: vh(2.06)
    line-height: 130%
    font-weight: 700
    width: calc(100% - vh(2.06))
    margin-bottom: vh(1.55)
    @media #{$tablet}
      font-size: 1.95vw
      width: calc(100% - 1.95vw)
      margin-bottom: 1.46vw
    @media #{$mobile}
      letter-spacing: -0.03em
      font-size: 3.75vw
      width: 100%
      margin-bottom: 3.13vw
    br
      display: none
      @media #{$mobile}
        display: block
  &-description
    font-size: vh(2.06)
    line-height: 120%
    font-weight: 700
    letter-spacing: -0.02em
    color: $darkBlue
    width: calc(100% - vh(2.06))
    text-align: right
    @media #{$tablet}
      font-size: 1.95vw
      width: calc(100% - 1.95vw)
    @media #{$mobile}
      font-size: 3.75vw
      width: 100%
    br
      display: none
      @media #{$mobile}
        display: block
  &-small
    font-size: vh(1.24)
    line-height: 130%
    font-weight: 700
    width: calc(100% - vh(2.06))
    @media #{$tablet}
      font-size: 1.17vw
      width: calc(100% - 1.95vw)
    @media #{$mobile}
      font-size: 2.5vw
      width: 100%
  &-button
    @include modalButton
  &-close
    @include modalCloseButton
