.workers-tab
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  @include whiteBlur
  &-content
    width: dpx(546)
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    padding: dpx(30) dpx(30) dpx(26) dpx(30)
    position: relative
    text-align: center
    @media #{$tablet}
      width: tpx(546)
      border-radius: tpx(40) 0 tpx(40) tpx(40)
      padding: tpx(30) tpx(30) tpx(26) tpx(30)
    @media #{$mobile}
      width: mpx(270)
      border-radius: mpx(25) 0 mpx(25) mpx(25)
      padding: mpx(20) mpx(20) mpx(14) mpx(20)
  &-image
    display: block
    width: 100%
    height: dpx(385)
    margin-bottom: dpx(25)
    object-fit: cover
    border-radius: dpx(20) dpx(20) 0 0
    background-color: $grey
    @include noSelect
    @media #{$tablet}
      height: tpx(385)
      margin-bottom: tpx(25)
      border-radius: tpx(20) tpx(20) 0 0
    @media #{$mobile}
      height: mpx(177)
      margin-bottom: mpx(12)
      border-radius: mpx(15) mpx(15) 0 0
  &-text
    font-size: dpx(32)
    font-weight: 700
    line-height: 120%
    letter-spacing: -0.02em
    color: $darkBlue
    @media #{$tablet}
      font-size: tpx(32)
    @media #{$mobile}
      font-size: mpx(16)
  &-buttons
    position: absolute
    left: 50%
    bottom: dpx(-30)
    transform: translate(-50%, 100%)
    display: flex
    align-items: center
    justify-content: center
    @media #{$tablet}
      bottom: tpx(-30)
    @media #{$mobile}
      bottom: mpx(-20)
  &-arrow
    background: transparent
    border: none
    cursor: pointer
    margin: 0 dpx(25)
    @media #{$tablet}
      margin: 0 tpx(25)
    @media #{$mobile}
      margin: 0 mpx(15)
    &--prev
      transform: scale(-1, 1)
    img
      display: block
      width: dpx(60)
      @include noSelect
      @media #{$tablet}
        width: tpx(60)
      @media #{$mobile}
        width: mpx(40)
  &-close
    @include modalCloseButton
