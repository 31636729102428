@keyframes wobble
  0%
    transform: translateY(0%)
  15%
    transform: translateY(-25%)
  30%
    transform: translateY(20%)
  45%
    transform: translateY(-15%)
  60%
    transform: translateY(10%)
  75%
    transform: translateY(-5%)
  100%
    transform: translateY(0%)

@keyframes loaderFirstDot
  0%
    opacity: 0
  30%
    opacity: 1
  60%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0

@keyframes loaderSecondDot
  0%
    opacity: 0
  30%
    opacity: 0
  60%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0

@keyframes loaderThirdDot
  0%
    opacity: 0
  30%
    opacity: 0
  60%
    opacity: 0
  90%
    opacity: 1
  100%
    opacity: 0