.geolocation-tab
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  @include whiteBlur
  &-content
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    position: relative
    text-align: center
    @media #{$tablet}
      border-radius: tpx(40) 0 tpx(40) tpx(40)
    @media #{$mobile}
      border-radius: mpx(25) 0 mpx(25) mpx(25)
  &-confirm
    width: dpx(490)
    padding: dpx(70) 0 dpx(96) 0
    @media #{$tablet}
      width: tpx(490)
      padding: tpx(70) 0 tpx(96) 0
    @media #{$mobile}
      width: mpx(268)
      padding: mpx(47) 0 mpx(55) 0
    &-title
      font-size: dpx(36)
      font-weight: 700
      line-height: 130%
      color: $blue
      margin-bottom: dpx(32)
      @media #{$tablet}
        font-size: tpx(36)
        margin-bottom: tpx(32)
      @media #{$mobile}
        font-size: mpx(20)
        margin-bottom: mpx(17)
    &-description
      font-size: dpx(24)
      font-weight: 700
      line-height: 130%
      @media #{$tablet}
        font-size: tpx(24)
      @media #{$mobile}
        font-size: mpx(14)
    &-button
      @include modalButton
      position: relative
      left: 0
      bottom: 0
      transform: none
      width: dpx(191)
      @media #{$tablet}
        width: tpx(191)
      @media #{$mobile}
        width: mpx(90)

  &-result
    width: dpx(500)
    padding: dpx(25) dpx(25) dpx(59) dpx(25)
    @media #{$tablet}
      width: tpx(500)
      padding: tpx(25) tpx(25) tpx(59) tpx(25)
    @media #{$mobile}
      width: mpx(268)
      padding: mpx(17) mpx(17) mpx(34) mpx(17)
    &-image
      width: 100%
      height: dpx(336)
      object-fit: cover
      display: block
      margin-bottom: dpx(32)
      @include noSelect
      background-color: $grey
      @media #{$tablet}
        height: tpx(336)
        margin-bottom: tpx(32)
      @media #{$mobile}
        height: mpx(174)
        margin-bottom: mpx(12)
    &-subtitle,
    &-count
      font-size: dpx(32)
      font-weight: 700
      line-height: 130%
      text-align: center
      @media #{$tablet}
        font-size: tpx(32)
      @media #{$mobile}
        font-size: mpx(20)
        letter-spacing: -0.03em
    &-count
      color: $blue
      margin-top: dpx(12)
      @media #{$tablet}
        margin-top: tpx(12)
      @media #{$mobile}
        margin-top: mpx(12)
    &-date
      font-size: dpx(12)
      font-weight: 700
      line-height: 130%
      margin-top: dpx(26)
      @media #{$tablet}
        font-size: tpx(12)
        margin-top: tpx(26)
      @media #{$mobile}
        font-size: mpx(10)
        margin-top: mpx(7)
    &-title
      font-size: dpx(64)
      line-height: 100%
      color: $blue
      text-transform: uppercase
      font-family: Prostokvashino
      filter: drop-shadow(0 dpx(2) dpx(5) rgba(0, 0, 0, 0.1))
      margin-bottom: dpx(12)
      letter-spacing: -0.03em
      @include stroke($white, 2px)
      @media #{$tablet}
        font-size: tpx(64)
        filter: drop-shadow(0 tpx(2) tpx(5) rgba(0, 0, 0, 0.1))
        margin-bottom: tpx(12)
      @media #{$mobile}
        font-size: mpx(32)
        filter: drop-shadow(0 mpx(2) mpx(3) rgba(0, 0, 0, 0.1))
        margin-bottom: mpx(5)
        @include stroke($white, 1px)
    &-description
      font-size: dpx(24)
      font-weight: 700
      line-height: 130%
      @media #{$tablet}
        font-size: tpx(24)
      @media #{$mobile}
        font-size: mpx(12)
    &-cow
      width: dpx(406)
      height: dpx(256)
      margin-bottom: dpx(25)
      object-fit: contain
      @media #{$tablet}
        width: tpx(406)
        height: tpx(256)
        margin-bottom: tpx(25)
      @media #{$mobile}
        width: mpx(210)
        height: mpx(150)
        margin-bottom: mpx(9)
    &-button
      white-space: nowrap
      @include modalButton

    .geolocation-tab-result-description,
    .geolocation-tab-result-title,
    .geolocation-tab-result-cow
      display: none
    &--empty
      width: dpx(530)
      padding: dpx(42) 0 dpx(77) 0
      @media #{$tablet}
        width: tpx(530)
        padding: tpx(42) 0 tpx(77) 0
      @media #{$mobile}
        width: mpx(268)
        padding: mpx(23) 0 mpx(36) 0
      .geolocation-tab-result-subtitle,
      .geolocation-tab-result-image,
      .geolocation-tab-result-count,
      .geolocation-tab-result-date
        display: none
      .geolocation-tab-result-description,
      .geolocation-tab-result-button,
      .geolocation-tab-result-title,
      .geolocation-tab-result-cow
        display: block

  &-select
    width: dpx(425)
    padding: dpx(33) 0 dpx(70) 0
    @media #{$tablet}
      width: tpx(425)
      padding: tpx(33) 0 tpx(70) 0
    @media #{$mobile}
      width: mpx(268)
      padding: mpx(20) 0 mpx(42) 0
    &-title
      font-size: dpx(36)
      font-weight: 700
      line-height: 130%
      color: $blue
      letter-spacing: -0.02em
      margin-bottom: dpx(30)
      @media #{$tablet}
        font-size: tpx(36)
        margin-bottom: tpx(30)
      @media #{$mobile}
        font-size: mpx(20)
        margin-bottom: mpx(15)
    &-option
      color: $black50
      font-size: dpx(24)
      font-weight: 700
      line-height: dpx(42)
      margin-bottom: dpx(5)
      cursor: pointer
      transition: font-size .1s
      @media #{$tablet}
        font-size: tpx(24)
        line-height: tpx(42)
        margin-bottom: tpx(5)
      @media #{$mobile}
        font-size: mpx(14)
        line-height: mpx(21)
        margin-bottom: mpx(6)
      &:nth-last-child(3)
        margin-bottom: 0 !important
      &:hover,
      &--active
        color: $black
        font-size: dpx(32)
        @media #{$tablet}
          font-size: tpx(32)
        @media #{$mobile}
          font-size: mpx(16)
    &-button
      white-space: nowrap
      @include modalButton

  &-buttons
    position: absolute
    left: 0
    bottom: 0
    transform: translateY(50%)
    display: flex
    justify-content: center
    column-gap: dpx(28)
    width: 100%
    @media #{$tablet}
      column-gap: tpx(28)
    @media #{$mobile}
      column-gap: mpx(18)
  &-close
    @include modalCloseButton
