.infospot
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  @include whiteBlur
  @media #{$mobile}
    align-items: flex-start
    padding-top: mpx(119)
    padding-bottom: 15vw
    overflow-y: auto
  &-content
    width: dpx(610)
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: dpx(40) 0 dpx(40) dpx(40)
    padding: dpx(30)
    position: relative
    @media #{$tablet}
      width: tpx(610)
      border-radius: tpx(40) 0 tpx(40) tpx(40)
      padding: tpx(30)
    @media #{$mobile}
      width: mpx(268)
      border-radius: mpx(25) 0 mpx(25) mpx(25)
      padding: mpx(18) mpx(18) mpx(20) mpx(18)
  &-image
    display: block
    width: 100%
    height: dpx(367)
    border-radius: dpx(20) dpx(20) 0 0
    margin-bottom: dpx(25)
    background-color: $grey
    object-fit: cover
    @include noSelect
    @media #{$tablet}
      height: tpx(367)
      border-radius: tpx(20) tpx(20) 0 0
      margin-bottom: tpx(25)
    @media #{$mobile}
      height: mpx(155)
      border-radius: mpx(15) mpx(15) 0 0
      margin-bottom: mpx(17)
  &-title
    font-size: dpx(24)
    font-weight: 700
    line-height: 130%
    color: $blue
    margin-bottom: dpx(9)
    width: calc(100% - dpx(15))
    @media #{$tablet}
      font-size: tpx(24)
      margin-bottom: tpx(9)
      width: calc(100% - tpx(15))
    @media #{$mobile}
      font-size: mpx(16)
      margin-bottom: mpx(10)
      width: 100%
  &-text
    font-size: dpx(20)
    font-weight: 700
    line-height: 130%
    width: calc(100% - dpx(15))
    @media #{$tablet}
      font-size: tpx(20)
      width: calc(100% - tpx(15))
    @media #{$mobile}
      font-size: mpx(12)
      width: 100%
    br
      @media #{$mobile}
        display: none
  &-close
    @include modalCloseButton
