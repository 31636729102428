.navbar
  position: fixed
  left: 50%
  top: 0
  z-index: 30
  width: 100%
  max-width: vh(136.84)
  display: flex
  align-items: center
  justify-content: flex-start
  border-radius: 0 0 dpx(29) dpx(29)
  filter: drop-shadow(0 vh(0.41) vh(0.52) rgba(0, 0, 0, 0.1))
  padding: vh(2.99) vh(6.5)
  background-color: transparentize($white, 0.15)
  transform: translateX(-50%)
  backdrop-filter: blur(5px)
  @media #{$tablet}
    border-radius: 0 0 tpx(29) tpx(29)
    filter: drop-shadow(0vw 0.39vw 0.49vw rgba(0, 0, 0, 0.1)) drop-shadow(0vw 0.39vw 0.49vw rgba(0, 0, 0, 0.2))
    max-width: 100%
    padding: 3.81vw 14.45vw 2.34vw 7.81vw
  @media #{$mobile}
    min-height: 21.88vw
    flex-direction: column
    align-items: center
    padding: 7.5vw 8.13vw
    filter: none
    border-radius: 0 0 mpx(25) mpx(25)
    box-shadow: 0vw 0.94vw 2.19vw rgba(0, 0, 0, 0.1), 0vw 0.94vw 2.19vw rgba(0, 0, 0, 0.1)
  &-opacity
    background-color: transparentize($white, 0.05)
    z-index: 120
    .navbar-item
      display: block !important
  button 
    background-color: transparent
    border: none
    padding: 0
    cursor: pointer
  &-overlay
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: calc(100 * var(--vh))
    z-index: -1
  &-item
    color: $blue
    font-size: vh(2.49)
    font-weight: 700
    line-height: vh(2.89)
    margin-right: vh(9.08)
    @media #{$tablet}
      font-size: 1.95vw
      line-height: 2.25vw
      margin-right: 4.59vw
    @media #{$mobile}
      display: none
      font-size: 5vw
      line-height: 5.63vw
      margin-top: 12.5vw
      margin-right: 0
    &:hover,
    &--active
      color: $corall
    &:nth-child(1)
      @media #{$mobile}
        margin-top: 33.13vw
    &:nth-child(3)
      margin-right: vh(26.21)
      @media #{$tablet}
        margin-right: 16.89vw
      @media #{$mobile}
        margin-right: 0
    &:nth-child(5)
      margin-right: 0
      @media #{$mobile}
        margin-bottom: 8.13vw
  &-logo 
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)
    @media #{$tablet}
      top: 1.95vw
    @media #{$mobile}
      top: 2.5vw
    img
      width: dpx(130)
      display: block
      @include noSelect
      @media #{$tablet}
        width: 10.16vw
      @media #{$mobile}
        width: 23.75vw
  .line
    background-color: $blue
    width: mpx(15)
    min-height: mpx(2.1)
    height: mpx(2.1)
    border-radius: mpx(3)
  &-menu-button 
    display: none
    position: absolute
    top: mpx(33)
    left: mpx(33)
    margin: 0
    @media #{$mobile}
      display: block
    &--line 
      margin-bottom: mpx(2.6)
      &:nth-child(3)
        margin-bottom: 0
  &-close-button
    display: none
    position: absolute
    top: mpx(33)
    left: mpx(35)
    width: mpx(12)
    height: mpx(12)
    margin: 0
    &--line 
      position: absolute
      &:nth-child(1)
        transform-origin: top left
        bottom: 0
        left: 0
        transform: rotate(-45deg)
      &:nth-child(2)
        transform-origin: top right
        bottom: 0
        right: 0
        transform: rotate(45deg)
  &-menu--open
    display: none
  &--open 
    display: block
    