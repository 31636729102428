.matroskin
  position: fixed
  bottom: 0
  right: calc(((100% - vh(136.84)) / 2) + vh(5.57))
  transition: transform .3s
  transform: translateY(vh(29))
  padding: vh(1.03) 0 0 0
  z-index: 2
  pointer-events: auto
  border-radius: 50% 0 0 0
  @media #{$tablet}
    right: 4.59vw
    transform: translateY(25.39vw)
    padding: 0.98vw 0 0 0
  @media #{$mobile}
    right: 1vw
    transform: translateY(37.5vw)
    padding: 3.13vw 0 0 0
  &:hover
    transform: translateY(vh(27.5))
    @media #{$tablet}
      transform: translateY(25.39vw)
    @media #{$mobile}
      transform: translateY(37.5vw)
  &-overlay
    width: 100%
    height: 100%
    position: fixed
    left: 0
    top: 0
    z-index: 100
    pointer-events: none
    &--hidden
      .matroskin
        transform: translateY(100%)
        @media #{$mobile}
          transform: translateY(110%)
    &--non-clickable
      pointer-events: none !important
      .matroskin-background
        opacity: 0 !important
      .matroskin
        pointer-events: none !important
    &--active
      pointer-events: auto
      .matroskin-background
        opacity: 1
      .matroskin
        transform: translateY(vh(16.82))
        @media #{$tablet}
          transform: translateY(15.63vw)
        @media #{$mobile}
          transform: translateY(20.94vw)
  &-background
    content: ''
    width: 100%
    height: 100%
    left: 0
    top: 0
    position: fixed
    z-index: 1
    opacity: 0
    @include whiteBlur
  &-image
    width: vh(28.28)
    display: block
    cursor: pointer
    position: relative
    z-index: 2
    border-radius: 50% 0 0 0
    @include noSelect
    @media #{$tablet}
      width: 26.76vw
    @media #{$mobile}
      width: 36.25vw
  button
    position: absolute
    right: vh(0.93)
    top: vh(0.1)
    background: transparent
    border: none
    cursor: pointer
    @media #{$tablet}
      right: 0.88vw
      top: 0.1vw
    @media #{$mobile}
      right: 3.5vw
      top: -1.5vw
    img
      width: vh(4.95)
      @media #{$tablet}
        width: 4.69vw
      @media #{$mobile}
        width: 8.75vw
  &-message
    position: absolute
    z-index: 1
    left: vh(3.5)
    bottom: vh(33.33)
    width: vh(41.28)
    padding: vh(2.06) vh(3.1)
    background-color: $blue
    border-radius: vh(1.86)
    transform: translate(-100%, 0)
    opacity: 1
    transition: opacity .4s, transform .6s
    pointer-events: auto
    @media #{$tablet}
      left: 2.5vw
      bottom: 31.54vw
      width: 39.06vw
      padding: 1.95vw 2.93vw
      border-radius: 1.76vw
    @media #{$mobile}
      left: 3vw
      bottom: 42vw
      width: 55vw
      padding: 3.13vw 0 3.13vw 4.3vw
      border-radius: 3.13vw
    &--big
      @media #{$mobile}
        padding: 3.13vw 4.3vw
        width: 60vw
    &::before
      content: ''
      width: vh(5.16)
      height: calc(100% - vh(2.06))
      position: absolute
      z-index: -1
      background-color: inherit
      right: vh(-0.63)
      top: 0
      border-radius: 0 vh(2.06) 0 0
      @media #{$tablet}
        width: 4.88vw
        height: calc(100% - 1.95vw)
        right: -0.59vw
        border-radius: 0 1.95vw 0 0
      @media #{$mobile}
        width: 15.63vw
        right: -1.5vw
        height: calc(100% - 3vw)
        border-radius: 0 3.13vw 0 0
    &--hidden
      opacity: 0
      pointer-events: none
      transform: translate(-100%, vh(3.1))
      @media #{$tablet}
        transform: translate(-100%, 2.93vw)
      @media #{$mobile}
        transform: translate(-100%, 9.38vw)
    p
      color: $white
      font-size: vh(2.48)
      line-height: 130%
      font-weight: 700
      position: relative
      z-index: 1
      @media #{$tablet}
        font-size: 2.34vw
      @media #{$mobile}
        font-size: 3.75vw
      br
        @media #{$mobile}
          display: none
        &.mobile
          display: none
          @media #{$mobile}
            display: block
    &-image
      display: block
      width: 100%
      margin-top: vh(1)
      margin-bottom: vh(2.58)
      border-radius: vh(1.55)
      border: 4px solid $white
      @include noSelect
      @media #{$tablet}
        margin-top: 1vw
        margin-bottom: 2.44vw
        border-radius: 1.46vw
      @media #{$mobile}
        margin-bottom: 3.75vw
        border-radius: 3.13vw
    &-pad
      position: absolute
      display: block
      height: vh(3.51)
      right: vh(-1.1)
      bottom: 0
      @include noSelect
      @media #{$tablet}
        height: 3.32vw
        right: -1.07vw
      @media #{$mobile}
        height: 7vw
        right: -2.5vw
      &--dark
        opacity: 0
    &-group
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: vh(3.4)
      @media #{$tablet}
        margin-top: 3.1vw
      @media #{$mobile}
        margin-top: 7.5vw
      &-step
        color: #fff
        font-size: vh(1.8)
        line-height: 145%
        font-weight: 700
        @media #{$tablet}
          font-size: 1.5vw
        @media #{$mobile}
          font-size: 4.3vw
        span
          color: inherit
          font-family: 'VAGRoundedTL'
          font-size: vh(3.5)
          line-height: 130%
          @media #{$tablet}
            font-size: 3.1vw
          @media #{$mobile}
            font-size: 6.8vw
      button
        position: static
        color: #000
        font-weight: 700
        font-size: vh(1.45)
        line-height: 120%
        padding: vh(1.3) 0
        background-color: #fff
        border-radius: vh(8.1)
        width: vh(13)
        box-shadow: 0px 5.25px 12px 0px #FFFFFF4F
        @media #{$tablet}
          font-size: vw
          padding: 1.1vw 0
          width: 11.7vw
          border-radius: 7.3vw
        @media #{$mobile}
          font-size: vw
          padding: 2.1vw 0
          width: 28.4vw
          border-radius: 23vw
