.background
  --scale: 1
  --scale-tablet: 1
  --scale-mobile: 1
  width: 1920px
  height: 975px
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%) scale(var(--scale))
  overflow: hidden
  @media #{$tablet}
    width: 1024px
    height: 1300px
    transform: translate(-50%, -50%) scale(var(--scale-tablet))
  @media #{$mobile}
    top: 0
    width: 320px
    height: 1809px
    transform: translate(-50%, 0%) scale(var(--scale-mobile))
    transform-origin: center top
    position: relative
  img
    @include noSelect
  &-scroll-to-top
    position: absolute
    bottom: 32px
    left: 50%
    border: none
    background: transparent
    cursor: pointer
    transform: translateX(-50%) scale(1, -1)
    display: none
    @media #{$mobile}
      display: block
    img
      display: block
      width: 40px
      height: 40px
  &-rights
    position: absolute
    left: 18.8%
    bottom: 7.79%
    display: flex
    flex-direction: column
    @media #{$tablet}
      left: 7.81%
      bottom: 2.31%
    @media #{$mobile}
      left: 6.25%
      bottom: 0.83%
    span, a
      font-size: dpx(12)
      font-weight: 700
      line-height: 140%
      color: $white
      text-shadow: 0px 0px dpx(20) $black, 0px 0px dpx(10) transparentize($black, 0.3)
      @media #{$tablet}
        font-size: tpx(12)
      @media #{$mobile}
        font-size: mpx(8)
    a
      text-decoration: underline
  &-map
    display: block
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center
    @include noSelect
  &-location
    position: absolute
    left: 0
    top: 0
    display: block
    height: auto
    opacity: 0
    transition: opacity .2s, transform .2s, filter .2s
    z-index: 1
    @include noSelect
    &--active
      opacity: 1 !important
      transform: scale(1.025) !important

  &-health
    width: 23.8%
    left: 25.1%
    top: 37.2%
    @media #{$tablet}
      width: 51.37%
      left: 4.1%
      top: 28.3%
    @media #{$mobile}
      width: 72.22%
      left: 0%
      top: 47.04%

  &-food
    width: 21.28%
    left: 46.6%
    top: 31%
    @media #{$tablet}
      width: 45.67%
      left: 47.5%
      top: 32.8%
    @media #{$mobile}
      width: 70.32%
      left: 26%
      top: 40.9%

  &-care
    width: 19.99%
    left: 16.2%
    top: 14.2%
    @media #{$tablet}
      width: 40.63%
      left: 6.7%
      top: 7.1%
    @media #{$mobile}
      width: 64.05%
      left: 36%
      top: 53%

  &-workers
    width: 8.99%
    left: 38%
    top: 25%
    @media #{$tablet}
      width: 23.44%
      left: 15.63%
      top: 73.33%
    @media #{$mobile}
      width: 38.73%
      left: 6%
      top: 78.12%

  &-geolocation
    width: 5.93%
    left: 51%
    top: 26%
    @media #{$tablet}
      width: 11.46%
      left: 74%
      top: 57.3%
    @media #{$mobile}
      width: 22.53%
      left: 60%
      top: 79.37%

  &-academy
    width: 19.4%
    left: 64%
    top: 19.7%
    @media #{$tablet}
      width: 39.03%
      left: 55.2%
      top: 8.35%
    @media #{$mobile}
      width: 68.73%
      left: 30.8%
      top: 67.9%

  &-quality
    width: 21.82%
    left: 11.5%
    top: 50.5%
    @media #{$tablet}
      width: 47.79%
      left: 3.5%
      top: 46.8%
    @media #{$mobile}
      width: 71.9%
      left: 1.9%
      top: 60.1%

  &-video
    width: 21.47%
    left: 64%
    top: 47.5%
    @media #{$tablet}
      width: 47.3%
      left: 47.2%
      top: 64.2%
    @media #{$mobile}
      width: 70.63%
      left: 26%
      top: 84.01%

  &-location-area
    position: absolute
    left: 0
    top: 0
    cursor: pointer
    z-index: 10
    border-radius: 50%
    &:hover + .background-location
      opacity: 1
      transform: scale(1.025)
      @media #{$tablet}
        opacity: 0
        transform: scale(1)

    &-health
      width: dpw(1121)
      height: dph(594)
      left: dpw(1603)
      top: dph(1135)
      @media #{$tablet}
        width: tpw(1389)
        height: tph(805)
        left: tpw(224)
        top: tph(1230)
      @media #{$mobile}
        width: mpw(1003)
        height: mph(595)
        left: mpw(39)
        top: mph(4330)

    &-food
      width: dpw(971)
      height: dph(706)
      left: dpw(2806)
      top: dph(980)
      @media #{$tablet}
        width: tpw(1212)
        height: tph(850)
        left: tpw(1600)
        top: tph(1430)
      @media #{$mobile}
        width: mpw(916)
        height: mph(580)
        left: mpw(540)
        top: mph(3780)

    &-care
      width: dpw(900)
      height: dph(726)
      left: dpw(1137)
      top: dph(500)
      border-radius: 50
      @media #{$tablet}
        width: tpw(1058)
        height: tph(868)
        left: tpw(308)
        top: tph(372)
      @media #{$mobile}
        width: mpw(878)
        height: mph(713)
        left: mpw(661)
        top: mph(4870)

    &-workers
      width: dpw(344)
      height: dph(317)
      left: dpw(2302)
      top: dph(779)
      border-radius: 30%
      @media #{$tablet}
        width: tpw(609)
        height: tph(572)
        left: tpw(538)
        top: tph(2908)
      @media #{$mobile}
        width: mpw(491)
        height: mph(485)
        left: mpw(158)
        top: mph(7034)

    &-geolocation
      width: dpw(180)
      height: dph(262)
      left: dpw(3008)
      top: dph(823)
      border-radius: 30%
      z-index: 11
      @media #{$tablet}
        width: tpw(247)
        height: tph(346)
        left: tpw(2325)
        top: tph(2300)
      @media #{$mobile}
        width: mpw(245)
        height: mph(359)
        left: mpw(1000)
        top: mph(7155)

    &-academy
      width: dpw(850)
      height: dph(730)
      left: dpw(3742)
      top: dph(660)
      @media #{$tablet}
        width: tpw(1000)
        height: tph(847)
        left: tpw(1770)
        top: tph(430)
      @media #{$mobile}
        width: mpw(881)
        height: mph(757)
        left: mpw(600)
        top: mph(6155)

    &-quality
      width: dpw(989)
      height: dph(720)
      left: dpw(880)
      top: dph(1550)
      @media #{$tablet}
        width: tpw(1278)
        height: tph(800)
        left: tpw(204)
        top: tph(2070)
      @media #{$mobile}
        width: mpw(935)
        height: mph(720)
        left: mpw(110)
        top: mph(5480)

    &-video
      width: dpw(981)
      height: dph(697)
      left: dpw(3729)
      top: dph(1401)
      @media #{$tablet}
        width: tpw(1256)
        height: tph(890)
        left: tpw(1551)
        top: tph(2650)
      @media #{$mobile}
        width: mpw(920)
        height: mph(651)
        left: mpw(508)
        top: mph(7598)
