.academy-tab
  position: fixed
  left: 0
  top: 0
  z-index: 15
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center
  @include whiteBlur
  @media #{$mobile}
    padding-bottom: 20vw
    overflow-y: auto
  &-content
    height: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background: transparentize($white, 0.075)
    border-radius: vh(4.13) 0 vh(4.13) vh(4.13)
    padding: vh(3.1) vh(3.1) vh(6.71) vh(3.1)
    position: relative
    @media #{$tablet}
      border-radius: 3.91vw 0 3.91vw 3.91vw
    @media #{$mobile}
      border-radius: 7.81vw 0 7.81vw 7.81vw
  &-close
    @include modalCloseButton
  &-button
    @include modalButton
    &--big
      width: vh(31.99)
      @media #{$tablet}
        width: 30.27vw
      @media #{$mobile}
        width: 56.5vw

@import './academy-game'
@import './academy-result'
@import './academy-tutorial'
