@font-face {
  font-family: 'RotondaC';
  src: url('./RotondaC.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RotondaC';
  src: url('RotondaC-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
  