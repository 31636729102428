.panorama-section
  position: fixed
  left: 0
  top: 0
  z-index: 20
  width: 100%
  height: 100%
  background-color: transparent
  transition: opacity .5s
  opacity: 0
  pointer-events: none
  &--visible
    opacity: 1
    pointer-events: auto
  .panorama-container
    width: 100%
    height: 100%
    background-color: $white !important
  .panorama-buttons
    position: absolute
    left: 50%
    bottom: dpx(40)
    transform: translateX(-50%)
    display: flex
    align-items: center
    justify-content: center
    @media #{$tablet}
      bottom: tpx(58)
    @media #{$mobile}
      bottom: mpx(43)
    button
      background: transparent
      border: none
      cursor: pointer
    .panorama-close
      margin: 0 dpx(25)
      @media #{$tablet}
        margin: 0 tpx(25)
      @media #{$mobile}
        margin: 0 mpx(12)
      img
        display: block
        width: dpx(72)
        @media #{$tablet}
          width: tpx(72)
        @media #{$mobile}
          width: mpx(49)
    .panorama-arrow
      &--prev
        transform: scale(-1, 1)
      img
        display: block
        width: dpx(60)
        @media #{$tablet}
          width: tpx(60)
        @media #{$mobile}
          width: mpx(40)

  .panorama-tutorial
    position: fixed
    left: 0
    top: 0
    z-index: 15
    width: 100%
    height: 100%
    display: flex
    align-items: flex-start
    justify-content: center
    padding-top: dpx(230)
    @include whiteBlur
    @media #{$tablet}
      padding-top: tpx(452)
    @media #{$mobile}
      padding-top: mpx(146)
    &-content
      width: dpx(374)
      height: auto
      display: flex
      align-items: center
      justify-content: center
      column-gap: dpx(70)
      background: transparentize($white, 0.075)
      border-radius: dpx(40) 0 dpx(40) dpx(40)
      padding: dpx(42) dpx(0) dpx(71) dpx(0)
      position: relative
      @media #{$tablet}
        width: tpx(374)
        column-gap: tpx(70)
        border-radius: tpx(40) 0 tpx(40) tpx(40)
        padding: tpx(42) tpx(0) tpx(71) tpx(0)
      @media #{$mobile}
        width: mpx(176)
        column-gap: mpx(25)
        border-radius: mpx(25) 0 mpx(25) mpx(25)
        padding: mpx(35) mpx(0) mpx(40) mpx(0)
    &-step
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      img
        display: block
        width: dpx(100)
        height: dpx(115)
        margin-bottom: dpx(16)
        @include noSelect
        @media #{$tablet}
          width: tpx(100)
          height: tpx(115)
          margin-bottom: tpx(16)
        @media #{$mobile}
          width: mpx(46)
          height: mpx(53)
          margin-bottom: mpx(7)
      p
        font-size: dpx(24)
        font-weight: 700
        line-height: 130%
        @media #{$tablet}
          font-size: tpx(24)
        @media #{$mobile}
          font-size: mpx(12)

  .panorama-controls
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 44px
    pointer-events: none
    display: none
